<template>
  <div ref="postForm">
    <a-form-model spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row class="main-container">
        <b-col>
          <a-form-model-item
            name="title"
            prop="title"
            extra="Maksimal 9 kata"
          >
            <a-input
              size="large"
              required
              placeholder="Insert title here"
              v-model="form.title"
            />
          </a-form-model-item>
          <div class="mb-3">
            <a-form-model-item class="editor" name="body" prop="body">
              <ckeditor
                required
                class="ckeditor"
                :editor="editor"
                v-model="form.body"
                @blur="bodyChanged"
                @ready="onEditorReady"
                :config="editorConfig"
              />
              <div class="editor__word-count text-muted">
                <div>
                  Words: <span>{{ words }}</span>
                </div>
                <div>
                  Characters: <span>{{ characters }}</span>
                </div>
              </div>
            </a-form-model-item>
          </div>
          <div class="card mb-3">
            <div class="card-header py-2 px-3 font-weight-bold">Excerpt</div>
            <div class="card-body p-3">
              <a-form-model-item class="mb-0" name="excerpt" prop="excerpt">
                <a-textarea required v-model="form.excerpt"></a-textarea>
              </a-form-model-item>
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-header py-2 px-3 font-weight-bold">SEO Settings</div>
            <div class="card-body p-3">
              <a-form-model-item class="mb-0" label="Meta Title" max="160" name="meta_title" prop="meta_title">
                <a-input v-model="form.meta_title" ></a-input>
              </a-form-model-item>
              <a-form-model-item class="mb-0" label="Meta Description">
                <a-textarea v-model="form.meta_description"></a-textarea>
              </a-form-model-item>
            </div>
          </div>
          <div class="card mb-3 mb-lg-0">
            <div class="card-header py-2 px-3 font-weight-bold">Gallery</div>
            <div class="card-body p-3">
              <ul class="gallery">
                <li v-for="(media, i) in form.imageMedia" :key="media.id">
                  <button
                    title="Remove"
                    class="gallery__remove"
                    @click="removeGalleryMedia(i)"
                  >
                    <i class="fe fe-x"></i>
                  </button>
                  <img
                    :src="`${media.url}?tr=w-100,h-100`"
                    :srcset="`${media.url}?tr=w-100,h-100 1x, ${media.url}?tr=w-200,h-200 2x`"
                    :alt="media.alt"
                  />
                </li>
                <li class="gallery__picker">
                  <button title="Add Media" @click="galleryMediaFinderOpen">
                    <i class="fe fe-plus"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col class="form-tools">
          <div class="card mb-3">
            <div class="card-header py-2 px-3 font-weight-bold">Publishing</div>
            <div class="card-body p-3">
              <a-form-model-item
                class="mb-0"
                label="Highlight"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-switch v-model="form.highlight" />
              </a-form-model-item>
              <a-form-model-item
                class="mb-0"
                label="Premium Content"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-switch v-model="form.is_premium" />
              </a-form-model-item>
              <a-form-model-item
                class="mb-0"
                label="Status"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-select
                  :default-value="0"
                  :disabled="!user.permissions_id.includes(71)"
                  v-model="form.is_published"
                  style="width: 190px"
                >
                  <a-select-option :value="0"> Draft </a-select-option>
                  <a-select-option :value="1" > Publish </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                class="mb-0"
                label="Post Type"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-select
                  :default-value="'post'"
                  v-model="form.post_type"
                  style="width: 190px"
                >
                  <a-select-option :value="'post'"> Post </a-select-option>
                  <a-select-option :value="'info_grafik'"> Info Grafik </a-select-option>
                  <a-select-option :value="'video'"> Video </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                class="mb-0"
                label="Published at"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-date-picker
                  show-time
                  v-model="form.published_at"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="Select Time"
                />
              </a-form-model-item>
              <a-form-model-item
                class="mb-0"
                label="Editor"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-auto-complete
                  v-model="form.editor_id"
                  :filter-option="filterOption"
                  @search="searchUsers"
                  @select="selectEditor"
                  :default-value="null"
                  style="width: 190px"
                  :data-source="userPortalData"
                  allow-clear
                  placeholder="Search Editor"
                >
                <template slot="dataSource">
                  <a-select-option v-for="user in userPortalData" :key="user.id" :value="user.id">
                    {{user.full_name}}
                  </a-select-option>
                </template>
                </a-auto-complete>
              </a-form-model-item>
              <a-form-model-item
                class="mb-0"
                label="Author"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-auto-complete
                  v-model="form.author_id"
                  :filter-option="filterOption"
                  @search="searchUsers"
                  @select="selectAuthor"
                  :default-value="null"
                  style="width: 190px"
                  :data-source="userPortalData"
                  allow-clear
                  placeholder="Search Author"
                >
                <template slot="dataSource">
                  <a-select-option v-for="user in userPortalData" :key="user.id" :value="user.id">
                    {{user.full_name}}
                  </a-select-option>
                </template>
                </a-auto-complete>
              </a-form-model-item>
              <a-form-model-item
                v-if="user.permissions_id.includes(81)"
                class="mb-0"
                label="PIC"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-select
                  v-model="form.pic_id"
                  :default-value="null"
                  style="width: 190px"
                >
                  <a-select-option :value="0"> Select PIC </a-select-option>
                  <a-select-option v-for="pic in picData" :key="pic.id" :value="pic.id"> {{pic.name}} </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                v-if="user.permissions_id.includes(81)"
                class="mb-0"
                label="Client"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-select
                  v-model="form.client_id"
                  :default-value="null"
                  style="width: 190px"
                >
                  <a-select-option :value="0"> Select Client </a-select-option>
                  <a-select-option v-for="client in clientData" :key="client.id" :value="client.id"> {{client.name}} </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                v-if="user.permissions_id.includes(81)"
                class="mb-0"
                label="Project"
                :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-select
                  v-model="form.project_id"
                  :default-value="null"
                  style="width: 190px"
                >
                  <a-select-option :value="0"> Select Project </a-select-option>
                  <a-select-option v-for="project in projectData" :key="project.id" :value="project.id"> {{project.name}} </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
            <div class="card-footer bg-gray-1 p-3">
              <b-row class="no-gutters">
                <b-col cols="5" class="pr-1" v-if="form && form.id">
                  <a class="btn btn-secondary block"  v-bind:href="`https://${portalDomain.replace(/^https?:\/\//,'')}/${form.slug}?preview=${previewToken}`" target="_blank" >Preview</a>
                </b-col>
                <b-col cols="7" class="pl-1">
                  <b-button variant="primary" @click="submit()" block :disabled="!user.permissions_id.includes(71) && form.is_published ? true: false">
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card mb-3">
            <div class="card-header py-2 px-3 font-weight-bold">Categories</div>
            <div class="card-body p-3">
              <a-form-model-item class="mb-0" name="categories" prop="categories">
                <a-tree
                  :tree-data="categoryTree"
                  v-model="form.categories"
                  default-expand-all
                  checkable
                />
                <a-icon slot="switcherIcon" type="down" />
              </a-form-model-item>
            </div>
          </div>

          <div class="card mb-3">
            <div class="card-header py-2 px-3 font-weight-bold">Tags</div>
            <div class="card-body p-3">
              <!-- <a-select
                mode="tags"
                :loading="tagLoading"
                @select="tagSelect"
                @search="tagSearch"
                @change="tagChange"
                v-model="form.tags"
                :options="tags"
                :filter-option="false"
                :token-separators="[',']"
                style="width: 100%"
                placeholder="Search Tag"
              /> -->
              <a-select
                mode="tags"
                :loading="tagLoading"
                @select="tagSelect"
                @search="tagSearch"
                @change="tagChange"
                v-model="form.tags"
                :filter-option="false"
                :token-separators="[',']"
                style="width: 100%"
                placeholder="Search Tag"
                option-label-prop="label"
              >
                <a-select-option v-for="item in tags" :key="item.key" :value="item.value" :label="item.label">
                  {{ item.label }}
                  <span role="img">
                    ({{ item.tag_count }})
                  </span>
                </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="card mb-3" v-if="form.post_type === 'video'">
            <div class="card-header py-2 px-3 font-weight-bold">Embed Video URL</div>
            <div class="card-body p-3">
              <a-form-model-item class="mb-0">
                <a-input
                  v-model="form.embeded_video"
                  placeholder="E.g.: https://youtu.be/f4g2nPY-VZc"
                ></a-input>
              </a-form-model-item>
              <div class="mt-2" v-if="form.embeded_video">
                <video-embed :src="form.embeded_video"></video-embed>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="card-header py-2 px-3 font-weight-bold">Image (Hero)</div>
            <div class="card-body p-3">
              <div class="media__preview" v-if="Object.keys(form.image_media).length">
                <button @click.prevent="removeMedia" class="media__remove">
                  <i class="fe fe-x"></i>
                </button>
                <img
                  :src="`${form.image_media.url}?tr=w-322`"
                  :srcset="`${form.image_media.url}?tr=w-322 1x, ${form.image_media.url}?tr=w-644 2x`"
                  :alt="form.image_media.alt"
                />
              </div>
              <b-button variant="info" @click.prevent="mediaFinderOpen" block>{{
                Object.keys(form.image_media).length ? "Change Media" : "Add Media"
              }}</b-button>
            </div>
          </div>

          <div class="card mb-0" v-if="form.image_media_id">
            <div class="card-header py-2 px-3 font-weight-bold">Custom Caption</div>
            <div class="card-body p-3">
              <a-form-model-item class="mb-0" label="Caption">
                <a-input v-model="form.image_meta_description"></a-input>
              </a-form-model-item>
              <a-form-model-item class="mb-0" label="Alt Caption">
                <a-input v-model="form.image_meta_alt"></a-input>
              </a-form-model-item>
              <a-form-model-item class="mb-0" label="Sumber Foto">
                <a-input v-model="form.image_meta_source"></a-input>
              </a-form-model-item>
            </div>
          </div>

          <div class="card mb-0" v-if="form.post_type === 'info_grafik'">
            <div class="card-header py-2 px-3 font-weight-bold">Info Grafik Media</div>
            <div class="card-body p-3">
              <div class="media__preview" v-if="Object.keys(form.info_grafik_media).length">
                <button @click.prevent="removeInfoGrafik" class="media__remove">
                  <i class="fe fe-x"></i>
                </button>
                <img
                  :src="`${form.info_grafik_media.url}?tr=w-322`"
                  :srcset="`${form.info_grafik_media.url}?tr=w-322 1x, ${form.info_grafik_media.url}?tr=w-644 2x`"
                  :alt="form.info_grafik_media.alt"
                />
              </div>
              <b-button variant="info" @click.prevent="infoGrafikMediaFinderOpen" block>{{
                Object.keys(form.info_grafik_media).length ? "Change Media" : "Add Media"
              }}</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </a-form-model>

    <!-- Media Finder for Post Content -->
    <b-modal
      hide-footer
      id="post-media-finder"
      size="xl"
      body-class="p-0 bg-light"
      centered
      title="Add Media"
    >
      <MediaFinder multiple v-on:insert="insertMediaToPost" />
    </b-modal>

    <!-- Media Finder for Featured/Hero Image -->
    <b-modal
      hide-footer
      id="media-finder"
      size="xl"
      body-class="p-0 bg-light"
      centered
      title="Add Media"
    >
      <MediaFinder :current="[form.image_media]" v-on:insert="applyMedia" />
    </b-modal>

    <!-- Info Grafik Media Finder for Featured/Hero Image -->
    <b-modal
      hide-footer
      id="infografik-media-finder"
      size="xl"
      body-class="p-0 bg-light"
      centered
      title="Add Media"
    >
      <MediaFinder :current="[form.info_grafik_media]" v-on:insert="applyInfoGrafik" />
    </b-modal>

    <!-- Media Finder for Gallery -->
    <b-modal
      hide-footer
      id="gallery-media-finder"
      size="xl"
      body-class="p-0 bg-light"
      centered
      title="Add Media"
    >
      <MediaFinder
        multiple
        no-control-pick
        :current="form.imageMedia"
        v-on:insert="applyGalleryMedia"
      />
    </b-modal>
    <b-modal
      hide-footer
      id="wsywyg-popularpost"
      size="xl"
      body-class="p-0 bg-light"
      centered
      title="Add Popular Post"
      >
      <modal-popularpost/>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MediaFinder from '../media/finder'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ModalPopularpost from './modal-popularpost.vue'
import { CKConfig } from '../../configs/ckeditor'
import store from 'store'

function escapeHtml(unsafe) {
  const output = unsafe.replace(/<[^>]+>/g, '')
  return output.slice(0, 160)
}
export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
    tagsids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rules: {
        title: [
          { required: true, message: 'Please insert a title' },
          { max: 160, message: 'Title should be less than 160 character' },
          { min: 5, message: 'Title should be min 5 character' },
        ],
        body: [{ required: true, message: 'Please write the body content' }],
        excerpt: [{ required: true, message: 'Please write the excerpt' }],
        categories: [{ required: true, message: 'Please choose at least 1 category' }],
        meta_title: [
          { required: true, message: 'Meta title is required' },
          { max: 160, message: 'Meta title should be less than 160 character' },
          { min: 5, message: 'Meta title should be min 5 character' },
        ],
      },
      mediaFile: [],
      checkedKeys: [],
      userPortalData: [],
      clientData: [],
      picData: [],
      projectData: [],
      authorsData: [],
      editor: ClassicEditor,
      editorConfig: CKConfig(this),
      words: 0,
      characters: 0,
      tag_ids: this.tagsids,
      editorReady: false,
      ckEditor: {},
      previewToken: '',
      portalDomain: '',
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    ...mapState('post', {
      form: (state) => state.form,
    }),
    ...mapState('category', {
      categories: (state) => state.data,
      categoryTree: (state) => state.dataTree,
    }),
    ...mapState('tag', {
      tags: (state) => state.data,
      tagLoading: (state) => state.loading,
      tagsForm: (state) => state.form,
    }),
    loading() {
      return this.$store.state.post.loading
    },
  },
  created() {
    const vm = this
    vm.$nprogress.start()
    const thisportal = vm.user.portals.find(f => f.id === vm.settings.activePortal)
    const accessToken = store.get('accessToken')
    vm.previewToken = `${btoa(accessToken)}`
    vm.portalDomain = thisportal.domain
    vm.$store.dispatch('category/FETCH', {
      portal_id: vm.settings.activePortal,
      perPage: 100,
      sort_column: 'title',
      sort_value: 'asc',
    }).then(cat => {
      vm.$store.dispatch('user/FETCH', {
        sort_column: 'username',
        sort_value: 'asc',
        active: 1,
        role_slug_not_in: ['audience'],
        portal_id: vm.settings.activePortal,
        perPage: 2000,
      }).then((res) => {
        vm.userPortalData = res.data.data
        if (vm.type !== 'create') {
          // @ts-ignore
          window.setdoc({
            userId: vm.user.id,
            status: true,
            date: new Date().toISOString(),
          }, parseInt(vm.$route.params.id)).then(d => {
            vm.$nprogress.done()
          })
          // @ts-ignore
          // window.getdoc(parseInt(vm.$route.params.id)).then(data => {
          // })
          // window.setdoc({status: false, userId: },res.data.data.id)
        }
        vm.$store.dispatch('client/FETCH', {
          sort_column: 'name',
          sort_value: 'asc',
          perPage: 2000,
        }).then((client) => {
          vm.clientData = client.data.data
          vm.$nprogress.done()
        })

        vm.$store.dispatch('project/FETCH', {
          sort_column: 'name',
          sort_value: 'asc',
          perPage: 2000,
        }).then((project) => {
          vm.projectData = project.data.data
          vm.$nprogress.done()
        })

        vm.$store.dispatch('pic/FETCH', {
          sort_column: 'name',
          sort_value: 'asc',
          perPage: 2000,
        }).then((pic) => {
          vm.picData = pic.data.data
          vm.$nprogress.done()
        })
      })
    })

    //
  },
  methods: {
    submit() {
      const vm = this
      vm.$refs.form.validate((valid) => {
        vm.$set(vm.form, 'tag_ids', vm.tag_ids)
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    bodyChanged() {
      const vm = this
      if (vm.form.excerpt === '') {
        vm.$set(vm.form, 'excerpt', escapeHtml(vm.form.body))
      }
      if (vm.type !== 'create') {
        // @ts-ignore
        window.setdoc({
          userId: vm.user.id,
          status: true,
          date: new Date().toISOString(),
        }, parseInt(vm.$route.params.id))
      }
    },
    selectEditor(value) {
      const vm = this
      vm.form.editor_id = value
    },
    selectAuthor(value) {
      const vm = this
      vm.form.author_id = value
    },
    selectProject(value) {
      const vm = this
      vm.form.project_id = value
    },
    searchProject(value) {
      const vm = this
      vm.$store.dispatch('project/FETCH', {
        sort_column: 'name',
        sort_value: 'asc',
        q: value,
        perPage: 2000,
      }).then((project) => {
        vm.projectData = project.data.data
        vm.$nprogress.done()
      })
    },

    searchUsers(value) {
      const vm = this
      vm.$store.dispatch('user/FETCH', {
        sort_column: 'username',
        sort_value: 'asc',
        active: 1,
        q: value,
        role_slug_not_in: ['audience'],
        portal_id: vm.settings.activePortal,
        perPage: 2000,
      }).then((res) => {
        vm.userDataSource = res.data.data.map((user) => {
          return {
            ...user,
            key: user.id,
          }
        })
      })
    },

    resetForm() {
      this.$refs.form.resetFields()
    },

    // Media
    mediaFinderOpen() {
      const vm = this
      vm.$bvModal.show('media-finder')
    },

    // Info Grafik Media
    infoGrafikMediaFinderOpen() {
      const vm = this
      vm.$bvModal.show('infografik-media-finder')
    },

    galleryMediaFinderOpen() {
      const vm = this
      vm.$bvModal.show('gallery-media-finder')
    },

    removeGalleryMedia(index) {
      const vm = this
      vm.form.imageMedia.splice(index, 1)
      vm.form.medias.splice(index, 1)
    },

    removeMedia() {
      const vm = this
      vm.$set(vm.form, 'image_media_id', null)
      vm.$set(vm.form, 'image_media', {})
    },

    removeInfoGrafik() {
      const vm = this
      vm.$set(vm.form, 'infografik_media_id', null)
      vm.$set(vm.form, 'info_grafik_media', {})
    },

    insertMediaToPost(media, medias) {
      const vm = this
      const editor = vm.ckEditor
      const content = editor.model.change((writer) => {
        const docFrag = writer.createDocumentFragment()
        medias.forEach((item) => {
          const imageElement = writer.createElement('image', {
            src: item.url,
          })
          writer.append(imageElement, docFrag)
        })
        return docFrag
      })
      editor.model.insertContent(content)
      vm.$bvModal.hide('post-media-finder')
    },

    applyMedia(media, medias) {
      //
      const vm = this
      vm.$set(vm.form, 'image_media', media)
      vm.$set(vm.form, 'image_media_id', media.id)
      vm.$bvModal.hide('media-finder')
    },

    applyInfoGrafik(media, medias) {
      //
      const vm = this
      vm.$set(vm.form, 'info_grafik_media', media)
      vm.$set(vm.form, 'infografik_media_id', media.id)
      vm.$bvModal.hide('infografik-media-finder')
    },

    applyGalleryMedia(media, medias) {
      const vm = this
      vm.$set(vm.form, 'imageMedia', medias)
      vm.$set(
        vm.form,
        'medias',
        medias.map((media) => {
          return media.id
        }),
      )
      vm.$bvModal.hide('gallery-media-finder')
    },

    // Editor
    onEditorReady(editor) {
      this.ckEditor = editor
      this.editorReady = true
    },

    // Tags
    tagSearch(search) {
      // this.$store.dispatch('tag/FETCH', { q: search })
      this.$store.dispatch('tag/TAGS_SEARCHING_WITH_TOTAL', {
        q: search,
        portal_id: this.settings.activePortal,
      })
    },

    tagChange(value) {
      const vm = this
      const ids = []
      value.forEach((tag) => {
        const slug = tag
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')
        vm.$store.dispatch('tag/GET_BY_SLUG', slug).then((res) => {
          if (!res.data) {
            vm.$set(vm.tagsForm, 'name', tag)
            vm.$set(vm.tagsForm, 'portal', [vm.settings.activePortal])
            vm.$set(vm.tagsForm, 'created_by', vm.user.id)
            vm.$store.dispatch('tag/STORE').then((result) => {
              ids.push(result.data.data.id)
            })
          } else {
            ids.push(res.data.id)
          }
        })
      })
      vm.$set(vm, 'tag_ids', ids)
      const search = value[0]
      this.$store.dispatch('tag/FETCH', { q: search })
    },

    authorChange(value, option) {
    },

    tagSelect(value, option) {
    },

  },

  watch: {
    'settings.activePortal': function () {
      const vm = this
      vm.$store.dispatch('category/FETCH', {
        portal_id: vm.settings.activePortal,
        perPage: 100,
        sort_column: 'title',
        sort_value: 'asc',
      })

      vm.$store.dispatch('user/FETCH', {
        sort_column: 'full_name',
        sort_value: 'asc',
        portal_id: vm.settings.activePortal,
        perPage: 2000,
      }).then((res) => {
        vm.userPortalData = res.data.data
      })

      const thisportal = vm.user.portals.find(f => f.id === vm.settings.activePortal)
      vm.previewHref = thisportal.domain
    },
  },

  components: {
    MediaFinder,
    // ModalRelatedpost,
    ModalPopularpost,
  },

  destroyed() {
    this.$store.commit('post/CLEAR_FORM')
    this.$store.commit('tag/CLEAR_DATA')
    this.$store.commit('tag/CLEAR_FORM')
  },
}
</script>

<style lang="scss">
@import "./style.module.scss";
.modal {
  // z-index: 998;
}
.editor {
  line-height: normal !important;
  .ant-form-item-control {
    line-height: normal !important;
  }
}
</style>
